(function ($) {
    $(document).ready(function () {
        const selectForm = $('form.select-search');

        selectForm.each(function () {
            let select = $(this).find('select.form-select');

            if (select.data('search') === true) {
                select.select2();
            } else {
                select.select2({
                    minimumResultsForSearch: -1
                });
            }
        });
    });
})(jQuery);